import React from 'react';
import { Button } from 'antd'
import '../css/index.css'
import Iframe from './Iframe'


const demoStyle = { position: "absolute", top: 815, padding: '15px 15%', textAlign: 'center', width: '100%', fontSize: 18, fontWeight: "600", lineHeight: '1.5' }

const demoStyles = { position: "absolute", top: 470, padding: '15px 15%', textAlign: 'center', width: '100%', fontSize: 18, fontWeight: "600", lineHeight: '1.5' }

class Demo extends React.Component {

    state = {
        show: true
    }


    clickDemo = (e, type) => {
        this.setState({
            show: type === 1 ? true : false
        })
    }

    clickToDemo = (e, href) => {
        window.open(href, "_blank")
    }

    render() {
        const { show } = this.state
        const { data } = this.props
        return (
            <div className="demo">
                <div className="container flexUl">
                    <ul>
                        <li onClick={(e) => this.clickDemo(e, 1)} style={show ? { borderBottom: '3px solid #3f51b5' } : {}}>体验</li>
                        {data.video_src && <li onClick={(e) => this.clickDemo(e, 2)} style={!show ? { borderBottom: '3px solid #3f51b5' } : {}}>演示视频</li>}
                    </ul>
                    {show ? <div className="demo_iframe" style={{ textAlign: 'center', height: data.height ? data.height + 90 : 890 }}>
                        {data.isIframe ? <div style={{ height: data.height ? data.height : 465, overflow: 'hidden', position: 'relative' }}>
                            <Iframe data={data}></Iframe>
                        </div> :
                            <div style={{ marginTop: 10 }}>
                                <img style={{ maxHeight: 450 }} src={require(`../assets/image/${data.src}`)} onClick={(e) => this.clickToDemo(e, data.href)} />
                            </div>}
                        <div style={{ position: "absolute", top: data.height + 15, padding: '15px 15%', textAlign: 'center', width: '100%', fontSize: 18, fontWeight: "600", lineHeight: '1.5' }} dangerouslySetInnerHTML={{ __html: data.provide }}>
                        </div>
                    </div> : <div>
                        <video controls src={(data.video_src && data.video_src.indexOf('http') > -1) ? data.video_src : require(`../assets/video/${data.video_src}`)} style={{ width: '100%', maxHeight: 450 }}>

                        </video>
                    </div>}
                </div>
            </div >
        );
    }
}

export default Demo;
